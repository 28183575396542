import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

import PreFooter from '../PreFooter';

import { FooterWrpStyled } from './footer.styled';

const FooterJSX = ({ data, preFooterContent }) => {
  const { acfSiteOptions } = data.wp.visionEnergySettings;
  const { footerMenuOne, footerMenuTwo, footerMenuThree, footerMenuFour } = data;
  const image = getImage(acfSiteOptions?.footerBackgroundImage?.localFile);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <footer>
        <PreFooter preFooterContent={preFooterContent} />
        <BackgroundImage Tag="div" className="footer-bg" {...bgImage}>
          <FooterWrpStyled>
            <div className="footer-inner-wrp ves-container">
              <div className="footer-content">
                <div className="logo-social-icons-wrp">
                  <div className="logo">
                    <img src={acfSiteOptions.alternativeLogo.sourceUrl} alt="footer-logo" />
                  </div>
                  <div className="social-media-icons">
                    <ul>
                      <li className="facebook">
                        <a
                          href={acfSiteOptions.facebook.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ves-transition">
                          <img src={acfSiteOptions.facebook.icon.sourceUrl} alt="facebook-icon" />
                        </a>
                      </li>
                      <li className="linkedin">
                        <a
                          href={acfSiteOptions.linkedin.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ves-transition">
                          <img src={acfSiteOptions.linkedin.icon.sourceUrl} alt="linkedin-icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="menu-wrp">
                  {footerMenuOne.nodes[0].menuItems.nodes.length > 0 && (
                    <div className="menu-item">
                      <h3>{acfSiteOptions.footerMenusColumn1.menuTitle}</h3>
                      <ul>
                        {footerMenuOne.nodes[0].menuItems.nodes.map((item) => (
                          <li key={item.id}>
                            <Link to={item.url} className="ves-transition">
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {footerMenuTwo.nodes[0].menuItems.nodes.length > 0 && (
                    <div className="menu-item">
                      <h3>{acfSiteOptions.footerMenusColumn2.menuTitle}</h3>
                      <ul>
                        {footerMenuTwo.nodes[0].menuItems.nodes.map((item) => (
                          <li key={item.id}>
                            <Link to={item.url} className="ves-transition">
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {footerMenuThree.nodes[0].menuItems.nodes.length > 0 && (
                    <div className="menu-item">
                      <h3>{acfSiteOptions.footerMenusColumn3.menuTitle}</h3>
                      <ul>
                        {footerMenuThree.nodes[0].menuItems.nodes.map((item) => (
                          <li key={item.id}>
                            <Link to={item.url} className="ves-transition">
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {footerMenuFour.nodes[0].menuItems.nodes.length > 0 && (
                    <div className="menu-item">
                      <h3>{acfSiteOptions.footerMenusColumn4.menuTitle}</h3>
                      <ul>
                        {footerMenuFour.nodes[0].menuItems.nodes.map((item) => (
                          <li key={item.id}>
                            <Link to={item.url} className="ves-transition">
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="footer-copyright">
                <div className="copyright-content">
                  <span>
                    All Rights Reserved
                    {acfSiteOptions.footerPrivacyPolicyLink && (
                      <Link to={acfSiteOptions.footerPrivacyPolicyLink} className="ml-1">
                        Privacy policy
                      </Link>
                    )}
                    {acfSiteOptions.footerTermsPageLink && (
                      <>
                        {' '}
                        |
                        <Link to={acfSiteOptions.footerTermsPageLink} className="ml-1">
                          Conditions of Use.
                        </Link>
                      </>
                    )}
                  </span>
                  <span>© {new Date().getFullYear()} – Vision Energy Solutions.</span>
                  <span>
                    Website by{' '}
                    <a
                      className="ves-transition"
                      href="http://www.smashydesign.com"
                      target="_blank"
                      rel="noopener noreferrer">
                      Smashy Design
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </FooterWrpStyled>
        </BackgroundImage>
      </footer>
    </>
  );
};

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query FooterSiteSettings {
          wp {
            visionEnergySettings {
              acfSiteOptions {
                alternativeLogo {
                  sourceUrl
                }
                facebook {
                  link
                  icon {
                    sourceUrl
                  }
                }
                linkedin {
                  link
                  icon {
                    sourceUrl
                  }
                }
                footerPrivacyPolicyLink
                footerTermsPageLink
                footerMenusColumn1 {
                  menuTitle
                }
                footerMenusColumn2 {
                  menuTitle
                }
                footerMenusColumn3 {
                  menuTitle
                }
                footerMenusColumn4 {
                  menuTitle
                }
                footerBackgroundImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 90, width: 1920)
                    }
                  }
                }
              }
            }
          }
          footerMenuOne: allWpMenu(filter: { locations: { eq: FOOTER_MENU_ONE } }) {
            nodes {
              menuItems {
                nodes {
                  url
                  label
                  id
                }
              }
            }
          }
          footerMenuTwo: allWpMenu(filter: { locations: { eq: FOOTER_MENU_TWO } }) {
            nodes {
              menuItems {
                nodes {
                  url
                  label
                  id
                }
              }
            }
          }
          footerMenuThree: allWpMenu(filter: { locations: { eq: FOOTER_MENU_THREE } }) {
            nodes {
              menuItems {
                nodes {
                  url
                  label
                  id
                }
              }
            }
          }
          footerMenuFour: allWpMenu(filter: { locations: { eq: FOOTER_MENU_FOUR } }) {
            nodes {
              menuItems {
                nodes {
                  url
                  label
                  id
                }
              }
            }
          }
        }
      `}
      render={(data) => <FooterJSX data={data} {...props} />}
    />
  );
}

FooterJSX.propTypes = {
  data: PropTypes.shape({
    wp: PropTypes.shape({
      visionEnergySettings: PropTypes.shape({
        acfSiteOptions: PropTypes.shape({
          footerPrivacyPolicyLink: PropTypes.string,
          footerTermsPageLink: PropTypes.string,
          facebook: PropTypes.shape({
            link: PropTypes.string,
            icon: PropTypes.shape({
              sourceUrl: PropTypes.string,
            }),
          }),
          linkedin: PropTypes.shape({
            link: PropTypes.string,
            icon: PropTypes.shape({
              sourceUrl: PropTypes.string,
            }),
          }),
          alternativeLogo: PropTypes.shape({
            sourceUrl: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
};
