module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontSize: {
      'xs': '.75rem',
      'sm': '.875rem',
      'tiny': '.875rem',
      'base': '1rem',
      'lg': '1.125rem',
      'xl': '1.25rem',
      '2xl': '1.5rem',
      '3xl': '2rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
      'h2': '1.75rem',
      'h1': '3.75rem',
    },
    extend: {
      fontFamily: {
        sans: ['Signika', 'sans-serif', 'Helvetica', 'Arial'],
      },
      colors: {
        success: {
          lighter: '#8AD83E',
          light: '#85E02D',
          DEFAULT: '#70B32F',
        },
        primary: {
          light: '#85d7ff',
          DEFAULT: '#0085d3',
          dark: '#000d3f',
          darker: '#0477BA',
          dimGray: '#6d6d6d',
        },
        gray: {
          lighter: '#f7f7f7',
          light: '#ededed',
          DEFAULT: '#a8a8a8',
          dark: '#6d6d6d',
          darker: '#333333',
        },
        secondary: {
          orangeLight: '#FFAE02',
          orange: '#FC9201',
          darkOrange: '#F08B02',
          darkerOrange: '#F05816',
          maroon: '#A83300',
          purple: '#7F0092',
        }
      },
      maxWidth: {
        'site': '1232px',
      }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/custom-forms'),
  ],
}
