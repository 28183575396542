import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

import { PreFooterWrpStyled } from './prefooter.styled';

export default function PreFooter({ preFooterContent }) {
  const { prefooterTitle, prefooterButtonLink, prefooterButtonText } = preFooterContent || {};

  return (
    <>
      {prefooterTitle && (
        <PreFooterWrpStyled>
          <div className="pre-footer-inner-wrp ves-container">
            <div className="pre-footer-wrp">
              {prefooterTitle && <h2 className="h2">{prefooterTitle}</h2>}
              {prefooterButtonLink && (
                <Link to={prefooterButtonLink.url} className="btn-cta contact-us">
                  {prefooterButtonText}
                </Link>
              )}
            </div>
          </div>
        </PreFooterWrpStyled>
      )}
    </>
  );
}

PreFooter.propTypes = {
  preFooterContent: PropTypes.shape({
    prefooterTitle: PropTypes.string,
    prefooterButtonText: PropTypes.string,
  }),
};
