import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Headroom from 'react-headroom';
import { useLocation } from '@reach/router';

import ChevronDown from '../../images/chevron-down.svg';
import Link from '../Link';

import {
  HeaderStyled,
  Logo,
  MainNavStyled,
  MenuItemStyled,
  SubMenuItemStyled,
} from './header.styled';

const HeaderJSX = ({ data, headerSpecificClass }) => {
  const location = useLocation();
  const [navActive, setNavActive] = useState(false);
  const [activeSubItem, setActiveSubItem] = useState();
  const menuItems = data.mainMenu.nodes[0].menuItems.nodes;
  const headerLogoSrc = data.wp.visionEnergySettings.acfSiteOptions.headerLogo.localFile.publicURL;

  const SubMenuGenerator = (parentId, menuData) => {
    const subMenuData = menuData.filter((menu) => menu.parentId === parentId);
    let menu = null;
    if (subMenuData?.length > 0) {
      menu = (
        <SubMenuItemStyled className="sub-menu">
          {subMenuData.map((item) => (
            <li key={item.id}>
              <Link to={item.url}>{item.label}</Link>
            </li>
          ))}
        </SubMenuItemStyled>
      );
    }
    return menu;
  };

  const MainMenuGenerator = (menuData) => {
    const menu = menuData.map((item) => {
      const subMenu = SubMenuGenerator(item.id, menuData);
      const isActive = item.url.split('||').includes(location.pathname);

      return item.parentId === null ? (
        <MenuItemStyled
          className={`${subMenu && 'group'}  ${activeSubItem === item.id ? 'active' : ''}`}
          key={item.id}>
          {!subMenu ? (
            <Link to={item.url}>{item.label}</Link>
          ) : (
            <>
              <button
                type="button"
                className={isActive ? 'is-active' : ''}
                onClick={() => {
                  setActiveSubItem(activeSubItem !== item.id ? item.id : null);
                }}>
                {item.label}

                <div className="dropdown-icon">
                  <ChevronDown />
                </div>
              </button>
              {subMenu}
            </>
          )}
        </MenuItemStyled>
      ) : null;
    });
    return menu;
  };

  return (
    <Headroom calcHeightOnResize className={`floating-header ${headerSpecificClass}`}>
      <HeaderStyled>
        <div className="ves-container header-container">
          <Logo>
            {/* <LogoHeader /> */}
            <Link to="/">
              <img src={headerLogoSrc} alt="header-logo" />
            </Link>
          </Logo>

          <MainNavStyled>
            <div className={`navWrapper ${navActive ? 'show' : ''}`}>
              <nav>
                <ul className="primary-nav">{MainMenuGenerator(menuItems)}</ul>
              </nav>
            </div>

            <div className="mobile-nav-button-wrapper">
              <button
                type="button"
                className={`mobile-nav-button ${navActive ? 'active' : ''}`}
                onClick={() => setNavActive(!navActive)}>
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </MainNavStyled>
        </div>
      </HeaderStyled>
    </Headroom>
  );
};

export default function Header(props) {
  return (
    <StaticQuery
      query={graphql`
        query Header {
          mainMenu: allWpMenu(filter: { locations: { eq: PRIMARY_NAVIGATION } }) {
            nodes {
              menuItems {
                nodes {
                  id
                  url
                  label
                  parentId
                  childItems {
                    nodes {
                      id
                      url
                      label
                      parentId
                    }
                  }
                }
              }
            }
          }
          wp {
            visionEnergySettings {
              acfSiteOptions {
                headerLogo {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <HeaderJSX data={data} {...props} />}
    />
  );
}

HeaderJSX.propTypes = {
  data: PropTypes.shape({
    mainMenu: PropTypes.objectOf(PropTypes.array),
    wp: PropTypes.shape({
      visionEnergySettings: PropTypes.shape({
        acfSiteOptions: PropTypes.shape({
          headerLogo: PropTypes.shape({
            localFile: PropTypes.shape({
              publicURL: PropTypes.string,
            }),
          }),
          alternativeLogo: PropTypes.shape({
            sourceUrl: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
  headerSpecificClass: PropTypes.string,
};
