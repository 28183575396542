import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header';
import Footer from '../components/footer';

// eslint-disable-next-line react/prop-types
export default function Layout({ children, preFooterContent, headerSpecificClass }) {
  return (
    <>
      <Header headerSpecificClass={headerSpecificClass} />
      <main>{children}</main>
      <Footer preFooterContent={preFooterContent} />
    </>
  );
}

const elementOrArrayOfElementPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.element,
]);

Layout.prototype = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(elementOrArrayOfElementPropType),
    elementOrArrayOfElementPropType,
  ]),
};
