import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const PreFooterWrpStyled = styled.div`
  // .pre-footer-inner-wrp {
  //   ${tw`-mb-44 md:-mb-36 lg:-mb-44 relative z-10`}

  .pre-footer-inner-wrp {
    ${tw`-mb-48 lg:-mb-44 relative z-10`}

    @media (min-width:500px) {
      margin-bottom: -10rem;
    }
    @media (min-width: 700px) {
      margin-bottom: -9rem;
    }

    .pre-footer-wrp {
      ${tw`bg-white rounded-3xl px-12 py-14 md:px-16 md:py-14 lg:py-24`}
      box-shadow: 0px 0px 60px #0000001A;

      h2 {
        ${tw`text-primary-dark mx-auto mb-10 md:mb-5 lg:mb-8 text-xl md:text-3xl leading-8 md:leading-10 text-center`}
        max-width: 768px;
      }

      .contact-us {
        ${tw`mx-auto`}
        max-width: 250px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 180px;
        }
      }
    }
  }
`;
