import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const HeaderStyled = styled.div`
  ${tw`flex w-full`}

  .header-container {
    ${tw`flex justify-between py-5 items-center`}
    z-index: 999;

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      height: fit-content;
    }
  }
`;

export const Logo = styled.div`
  ${tw`flex justify-between justify-center items-center z-10`};
  width: clamp(75px, 10vw, 120px);
`;

export const MainNavStyled = styled.div`
  ${tw`flex justify-end`}

  nav {
    ${tw`w-full`};
  }

  .mobile-nav-button-wrapper {
    ${tw`overflow-hidden w-12 h-12 block relative lg:hidden`};
  }

  .mobile-nav-button {
    ${tw`lg:hidden z-40 scale-50 p-4 bg-white rounded-full absolute`}
    outline: none;
    transform: translate(-50%, -50%) scale(0.35);
    top: 50%;
    margin-left: 50%;

    svg {
      //${tw`scale-50`}
    }

    .line {
      fill: none;
      stroke: black;
      stroke-width: 7;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 7;
    }

    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 7;
    }

    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 7;
    }

    &.active .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 7;
    }
    &.active .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 7;
    }
    &.active .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 7;
    }
  }

  .navWrapper {
    ${tw`fixed flex p-8 lg:p-0 lg:relative transition transition-all ease-in-out duration-300 flex-col lg:flex-row justify-center items-center`}
    ${tw`w-full h-screen lg:h-full left-0 lg:relative bg-white lg:bg-transparent bg-opacity-100 top-0`}
    transform: translateY(-100%);

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      transform: translateY(0);
    }

    &.show {
      ${tw``}
      transform: translateY(0);

      @media (max-width: 1023px) {
        height: calc(100vh + 110px);
      }

      nav {
        ${tw`pt-28 md:pt-20 lg:pt-0`}

        @media (max-width: 1023px) {
          height: 700px;
          overflow-y: scroll;
        }
      }
    }
  }

  .primary-nav {
    ${tw`flex items-start lg:items-center flex-col lg:flex-row`}

    > li {
      &:last-child {
        a {
          ${tw`md:bg-primary-dark md:text-white md:py-3 md:px-11`}

          &:hover {
            ${tw`md:bg-primary`}
          }
        }
      }
    }

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      .sub-menu {
        ${tw``}
      }
    }
  }
`;

export const MenuItemStyled = styled.li`
  // Desktop styles
  @media (min-width: ${fullConfig.theme.screens.lg}) {
    ${tw`flex items-center justify-center relative ml-8`};

    &:hover {
      > a,
      button {
        ${tw`text-primary`};
      }
    }

    > a,
    button {
      ${tw`transition transition-all ease-in-out duration-200 text-base text-primary-dark font-semibold group-hover:text-primary uppercase outline-none`};

      &.is-active {
        ${tw`text-primary`}
      }
    }

    .dropdown-icon {
      display: none;
    }

    .sub-menu {
      ${tw`rounded-md`};
    }
  }

  a,
  button {
    @media (min-width: 1024px) and (max-width: 1100px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1023px) {
    ${tw`flex flex-col items-start justify-start relative mb-12 opacity-75`};

    &.group {
      opacity: 1 !important;
    }

    > a,
    button {
      ${tw`text-xl flex items-center text-primary-dark font-bold group-hover:text-primary uppercase outline-none`};
    }

    .dropdown-icon {
      ${tw`w-4 ml-4 transition transition-all ease-in-out duration-200 transform rotate-0`};
    }

    .sub-menu {
      max-height: 0;
      ${tw`transition transition-all ease-in-out duration-200 overflow-y-hidden ml-6 uppercase`};

      a {
        ${tw`text-primary-dark py-2 flex font-semibold`};
      }
    }

    &.active {
      .dropdown-icon {
        ${tw`w-4 ml-4 rotate-180`};
      }

      .sub-menu {
        max-height: 200px;
      }
    }
  }
`;

export const SubMenuItemStyled = styled.ul`
  // Desktop styles
  @media (min-width: ${fullConfig.theme.screens.lg}) {
    ${tw`absolute shadow-xl px-6 py-8 bg-white items-center transition transition-all ease-in-out duration-200 opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto`};
    top: 100%;
    min-width: 235px;
    left: -25px;

    li {
      ${tw`mb-4 last:mb-0`};
    }

    a {
      ${tw`text-sm text-primary-dark font-semibold hover:text-primary uppercase `};

      &.is-active {
        ${tw`text-primary`}
      }
    }
  }
`;
