import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GatsbyLinkStyled = styled(GatsbyLink)``;

const AnchorStyled = styled(GatsbyLinkStyled).attrs({
  as: 'a',
})``;

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLinkStyled
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}>
        {children}
      </GatsbyLinkStyled>
    );
  }
  return (
    <AnchorStyled href={to} {...other} rel="noopener" target="_blank">
      {children}
    </AnchorStyled>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
};

Link.defaultProps = {
  activeClassName: 'is-active',
  partiallyActive: true,
};

export default Link;
